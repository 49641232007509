import * as React from 'react'
import Layout from '../components/layout'
import {Link} from "gatsby";

const IndexPage = () => {
    return (
        <Layout pageTitle="">
	        <Link to="/search">Voir le POC Algolia</Link>
	        <h2>Catégories</h2>
	        <Link to="/c/Salé/Tartes, Quiches et Pizza/Pizza">Pizza</Link>
        </Layout>
    )
}

export default IndexPage